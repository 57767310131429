<template>
  <div>
    <div class="width_1200">
      <div class="bread">
        <bread-crumb></bread-crumb>
      </div>
      <div>
        <writing></writing>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/breadcrumb.vue";
import writing from "@/components/writing.vue";
export default {
  components: {
    BreadCrumb, writing
  },
  computed: {},
  watch: {},
  data () {
    return {
    };
  },
  mounted () {

  },
  methods: {

  },
  beforeDestroy () {
  }
};
</script>
<style lang="less" scoped>
</style>
